import {CommunityPostComment} from "../models/community/community-post-comment.model";
import {CommunityPost} from "../models/community/community-post.model";
import {CommunityPostsService} from "./services/community-posts.service";
import {CommunityMemberSettings} from '../models/community/community-member-settings.model';
import {User} from '../models/user.model';
import {CommunitySettings} from '../models/community/community-settings.model';
import {CommunityRoom} from '../models/community/community-room.model';
import {CommunityRoomUserAccess} from '../models/community/community-room-user-access.model';
import {Timestamp, arrayUnion, arrayRemove, FieldValue, increment} from '@firebase/firestore';
import {CommunityAccess} from '../models/community/community-access.model';
import {MessagesService} from '../shared/services/messages.service';
import {instructorPlans} from '../admin/school-settings/instructor-plans';
import {TenantInfo} from '../models/tenant.model';



export const ONE_WEEK_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

export function prepPreviewComments(post: CommunityPost,comment: CommunityPostComment): CommunityPost {

  if (post.previewComments.length >= 2) {
    console.log('preview comments already has 2 comments');
    return null ;
  }

  post.previewComments.push(comment);

  return post;
}

export async function likePost(post: CommunityPost, userId: string, communityPostsService: CommunityPostsService) {

  const updatedPost = {
    id: post.id,
    likes: increment(1),
    likedBy: arrayUnion(userId),
    lastUpdatedAt: Timestamp.now(),
  } as CommunityPost

  console.log("updating post with like", updatedPost);

  await communityPostsService.likeCommunityPost(updatedPost);

  return updatedPost;
}

export async function unlikePost(post: CommunityPost, userId: string, communityPostsService: CommunityPostsService) {

  const updatedPost = {
    id: post.id,
    likes: increment(-1),
    likedBy: arrayRemove(userId),
    lastUpdatedAt: Timestamp.now(),
  } as CommunityPost

  await communityPostsService.likeCommunityPost(updatedPost);

  return updatedPost;
}


export function sortPosts(posts: CommunityPost[], currentUser: any) {
  let sortedPosts = [...posts];

  sortedPosts.sort((a, b) => {
    // const isNewA = checkIfIsNewPost(a, currentUser.communityMemberSettings);
    // const isNewB = checkIfIsNewPost(b, currentUser.communityMemberSettings);

    if (a.pinned && !b.pinned) {
      return -1;
    } else if (!a.pinned && b.pinned) {
      return 1;
    // } else if (isNewA && !isNewB) {
    //   return -1;
    // } else if (!isNewA && isNewB) {
    //   return 1;
    } else {
      return b.createdAt.toMillis() - a.createdAt.toMillis();
    }
  });

  return sortedPosts;
}


export function checkIfIsNewPost(post: CommunityPost, communityMemberSettings: CommunityMemberSettings): boolean {

  const currentTime = new Date().getTime();
  const alreadyRead =
    communityMemberSettings?.postsWithReadCommentsIds?.length > 0
    ? communityMemberSettings?.postsWithReadCommentsIds?.includes(post.id)
    : false;

  if (post && post?.createdAt && !alreadyRead) {

    const postTime = post.createdAt.toMillis();
    const timeDifference = currentTime - postTime;

    return timeDifference < ONE_WEEK_IN_MILLISECONDS; // if the post is created within 1 week
  }

  return false; // Default to false if post or createdAt is not available
}

export async function removePostIdFromCommunityMemberSettings(communityPostsService: CommunityPostsService, user: User, postId: string) {

  console.log('removing post id from community member settings');

  try {

    let communityMemberSettings = user?.communityMemberSettings;

    if (!communityMemberSettings) {
      return null;
    }

    const postsWithReadCommentsIds = communityMemberSettings?.postsWithReadCommentsIds;

    if (!postsWithReadCommentsIds?.includes(postId)) {
      console.log('postId not found in postsWithReadCommentsIds');
      return null;
    }

    const filteredPosts = postsWithReadCommentsIds.filter(id => id !== postId);

    console.log('filteredPosts', filteredPosts);

    const changes = {
      communityMemberSettings: {
        ...communityMemberSettings,
        postsWithReadCommentsIds: filteredPosts ?? []
      }
    }

    await communityPostsService.updateCurrentUser(user.id, changes);

    return changes;

  } catch (err) {

    console.log('error updating community member settings', err);

  }

}

export async function addPostIdToCommunityMemberSettings(communityPostsService: CommunityPostsService, user: User, postId: string) {

  console.log('updating community member settings')

  try {

    let communityMemberSettings = user?.communityMemberSettings;

    // Create a new object for communityMemberSettings if it does not exist
    if (!communityMemberSettings) {
      communityMemberSettings = {} as CommunityMemberSettings;
    }

    const postsWithReadCommentsIds = communityMemberSettings?.postsWithReadCommentsIds ?? [];

    if (postsWithReadCommentsIds?.includes(postId)) { // post already read
      console.log('post already read');
      return null;
    }

    const changes = {
      communityMemberSettings: {
        ...communityMemberSettings,
        postsWithReadCommentsIds: [ ...postsWithReadCommentsIds, postId ]
      }
    }

    await communityPostsService.updateCurrentUser(user.id, changes);

    console.log('updated community member settings');

    return changes;

  } catch (err) {

    console.log('error updating community member settings', err);

  }
}

export function isUserAllowedInCommunity(communitySettings: CommunitySettings, isAdmin: boolean, currentUser: User) {

  const communityAccess = communitySettings?.communityAccess;

  if (communityAccess === 'free') {
    console.log('COMMUNITY ACCESS community access is free for everyone, students and admins.');
    return true;
  }

  if (isAdmin) {
    console.log('COMMUNITY ACCESS user is admin, granting access to community.');
    return true;
  }

  // check if user is invited to the community using invitation link
  if (isMemberOfInviteOnlyCommunity(communityAccess, currentUser)) {
    console.log('COMMUNITY ACCESS user is community member by invitation link, granting access to community.');
    return true;
  }

  // check if user has subscribed to the community
  if (isMemberOfPaidCommunity(communityAccess, currentUser)) {
    console.log('COMMUNITY ACCESS user has subscribed to the community, granting access to community.');
    return true;
  }

  console.log(`COMMUNITY ACCESS user ${currentUser.email}  is not allowed in community.`);
  return false;
}

export function isMemberOfInviteOnlyCommunity(communityAccess: CommunityAccess, currentUser: User) {

  const userInvited = currentUser?.invitationLinkId && currentUser?.isCommunityMember  // && communityAccess === 'invite-only' ;

  if (userInvited) {
    console.log('user is community member by invitation link');
    return true;
  }

  return false;
}

export function isMemberOfPaidCommunity(communityAccess: CommunityAccess, currentUser: User) {

  console.log(`checking COMMUNITY ACCESS: ###:`, currentUser);

  // const userInvited = currentUser?.invitationLinkId && currentUser?.isCommunityMember;
  const communitySubscriptionPlan = currentUser?.communityMemberSettings?.communitySubscriptionPlan;
  const communityPlanEndsAt = currentUser?.communityMemberSettings?.communityPlanEndsAt;
  let subscriptionActive = false;

  console.log(`COMMUNITY ACCESS checking if community subscription is active, communitySubscriptionPlan: ${communitySubscriptionPlan}, communityPlanEndsAt: ${communityPlanEndsAt}`);

  if (!communityPlanEndsAt) {
    console.log('COMMUNITY ACCESS communityPlanEndsAt is not undefined, subscription is still active.');
    subscriptionActive = true; // if communityPlanEndsAt is undefined, then subscription is active
  }
  else {
    console.log('COMMUNITY ACCESS communityPlanEndsAt is defined, checking if it is greater than current time.');
  }

  if (communityPlanEndsAt && communityPlanEndsAt?.toMillis() > new Date().getTime()) {
    console.log('COMMUNITY ACCESS communityPlanEndsAt is greater than current time, subscription is active');
    subscriptionActive = true; // if communityPlanEndsAt is available and is greater than current time, then subscription is active
  }

  console.log(`COMMUNITY ACCESS checking if user has subscribed to the community,
    communityAccess: ${communityAccess},
    communitySubscriptionPlan:${communitySubscriptionPlan}
    communityStripeSubscriptionId: ${currentUser?.communityMemberSettings?.communityStripeSubscriptionId},
    isCommunityMember: ${currentUser?.isCommunityMember},
    currentUser: `, currentUser);

  if (communityAccess === 'community-subscription'
    && (communitySubscriptionPlan === 'year' || communitySubscriptionPlan === 'month')
    && currentUser?.communityMemberSettings?.communityStripeSubscriptionId
    && currentUser.isCommunityMember
    && subscriptionActive) {
    console.log('COMMUNITY ACCESS user has subscribed to the community', communitySubscriptionPlan);
    return true;
  }
  else {
    console.log('COMMUNITY ACCESS user is not allowed in community via subscription.');
  }

  console.log('COMMUNITY ACCESS unknown scenario, user is not allowed in community.');
  return false;
}

export function isUserAllowedInRoom(room: CommunityRoom, isAdmin: boolean, roomUserAccess: CommunityRoomUserAccess[]) {

  if (room?.readAccess == 'everyone') {
    console.log('room can be accessed by everyone');
    return true;
  }

  if (isAdmin) {
    console.log('user is an admin');
    return true;
  }

  const roomSubscribed = roomUserAccess?.find(access => {
    let subscriptionActive = false;

    if (!access?.communityRoomPlanEndsAt) {
      console.log('communityRoomPlanEndsAt is not available');
      subscriptionActive = true; // if communityRoomPlanEndsAt is undefined, then subscription is active
    }

    if (access?.communityRoomPlanEndsAt && access?.communityRoomPlanEndsAt?.toMillis() > new Date().getTime()) {
      subscriptionActive = true; // if communityRoomPlanEndsAt is present and is greater than current time, then subscription is active
    }

    return access?.roomId == room?.id && room?.readAccess == 'room-subscription' && access?.communityRoomStripeSubscriptionId && subscriptionActive;
  });

  if (roomSubscribed) {
    console.log('user has subscribed to the room');
    return true;
  }

  const roomIdExist = roomUserAccess?.find(access => {
    return access?.roomId == room?.id && access?.isRoomMember // && room.readAccess == 'invite-only'
  });

  if (roomIdExist) {
    console.log('user has been given access to the room.');
    return true;
  }

  return false;
}

export function  hasWriteAccessForEveryoneOrIsAdmin(room: CommunityRoom, isAdmin: boolean, user: User) {

  if (!room) { // if room is not available
    return true;
  }

  if(room?.writeAccess == 'everyone') {
    return true;
  }

  if(isAdmin) {
    return true;
  }

  if(room?.writeAccess === 'community-admins-only') {

    const badges = user?.communityMemberSettings?.badges;

    if(badges && badges.length > 0 && badges?.includes('moderator')) {
      return true;
    }

  }

  return false;

}

export function filterUnauthorizedMembers(communitySettings: CommunitySettings, members: User[]) { // filter out members who are not allowed in the community

  return members.filter(member => {
    return isUserAllowedInCommunity(communitySettings, false, member) && !member.email.endsWith('onlinecoursehost.com');
  });
}

export function checkForActiveRoomSubscriptions(currentUser: User) {
  let roomsAccessible = currentUser?.communityMemberSettings?.roomAccess;

  roomsAccessible = roomsAccessible?.filter(access => {
    return access?.communityRoomStripeSubscriptionId &&
      (access?.communityRoomSubscriptionPlan === 'month' || access?.communityRoomSubscriptionPlan === 'year')
      && !access.communityRoomPlanEndsAt;
  });

  return roomsAccessible
}

export function getCommunityMemberInitials(name: string): string {

  if (!name) {
    return '';
  }

  name = name.trim();

  const nameParts = name.split(' ');

  if (nameParts.length === 1) {
    return nameParts[0].charAt(0).toUpperCase();
  } else if (nameParts.length === 2) {
    const firstPart = nameParts[0].charAt(0).toUpperCase();
    const lastPart = nameParts[1].charAt(0).toUpperCase();
    return `${firstPart}${lastPart}`;
  } else {
    return nameParts[0].charAt(0).toUpperCase();
  }
}


export function extractTextAroundCursor(textAreaValue: string, cursorPos: number, emoji: string): string {

  let textBeforeCursor =  [...textAreaValue].slice(0, cursorPos)

  textBeforeCursor.push(emoji)

  let textAfterCursor = [...textAreaValue].slice(cursorPos, textAreaValue.length)

  return [...textBeforeCursor, ...textAfterCursor].join("");
}

export function showMaximumActiveCommunityMembersReached( messages: MessagesService, isMaxActiveMembersReached: boolean, adminEmail: string) {

  if (!isMaxActiveMembersReached) {
   return false;
  }

  messages.i18nWarn('community.message.common.maxCommunityMembersReached', { adminEmail });

  return true;
}

export function isMaxActiveMembersReached(tenant: TenantInfo, isActiveCommunityMember: boolean, isMainAdmin: boolean) {

  const maxCommunityMembers = instructorPlans[tenant.instructorPricingPlan]?.maxCommunityMembers ?? 0;
  const totalActiveMembers = tenant?.communitySettings?.totalActiveMembers ?? 0;

  return !isActiveCommunityMember && !isMainAdmin ? totalActiveMembers >= maxCommunityMembers : false;
}

export function refreshPage() {
  setTimeout(() => {
    window.location.reload();
  }, 2000);
}

